//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { statisticsNumber } from '@/api/modular/fileStream/documentManage'
export default {
  data() {
    return {
      tabMsg: {
        receivedNum: {
          route: {
            path: '/mobileDocumentApproval',
            query: {
              index: 2,
            },
          },
          text: '已收公文',
          img: require('@/assets/img/mobile/ysgw.png'),
          num: 0,
          color: 'rgba(255, 138, 33, 0.25)',
          bg_color: 'rgba(255, 138, 33, 0.25)',
        },
        auditedNum: {
          route: {
            path: '/mobileDocumentApproval',
            query: {
              index: 1,
            },
          },
          text: '已審公文',
          img: require('@/assets/img/mobile/ysh.png'),
          num: 0,
          color: 'rgba(241, 8, 8,0.25)',
        },
        acceptSendNum: {
          route: {
            path: '/mobileDocumentApproval',
            query: {
              index: 3,
            },
          },
          text: '文書歸檔',
          img: require('@/assets/img/mobile/wsgd.png'),
          num: 0,
          color: 'rgba(110, 84, 246,0.25)',
          bg_color: 'rgba(255, 175, 54, 0.15)',
        },
      },
    }
  },
  mounted() {
    this.getStatisticsNumber()
  },
  methods: {
    getStatisticsNumber() {
      statisticsNumber()
        .then((res) => {
          if (res.code === 200) {
            const keys = Object.keys(res.data)
            keys.forEach((element) => {
              if (this.tabMsg[element]) {
                this.tabMsg[element].num = res.data[element]
              }
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    toPath({ route }) {
      route && this.$router.push(route)
    },
  },
}
