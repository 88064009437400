//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { pendingNumber } from '@/api/modular/fileStream/documentManage'
import { mapGetters } from 'vuex'
import minxin, { initWx } from '../components/minxin'
import tableList from './components/list.vue'
import tab from './components/tab.vue'

export default {
    data() {
        return {
            active: 0,
            keyword: '',
            pendingNumber: 0,
            list: [
                {
                    text: '',
                },
            ],
            approvalTab: 1,
        }
    },
    mixins: [minxin, initWx],
    components: {
        tableList,
        tab,
    },
    computed: {
        ...mapGetters(['supers', 'commons', 'auth_code']),
    },
    watch: {
        '$route.query.index': {
            handler(val) {
                this.changeTabStatus(Number(this.$route.query.index) || 0)
            },
            immediate: true,
        },
    },
    mounted() {
        const { index } = this.$route.params
        if (index === 0 || index) {
            this.active = index
            this.approvalTab = index + 1
            this.$refs.TableMess.refresh({ approvalTab: index + 1 })
        }
        this.initNumber()
        // this.languages()// wn修改語言
    },
    methods: {
        // languages: () => {
        //     alert("zhes")
        // },
        changeTabStatus(evt) {
            this.active = evt
            this.approvalTab = evt + 1
            this.$refs.TableMess && this.$refs.TableMess.refresh({ approvalTab: evt + 1 })
        },
        handleChange(evt) {
            this.active = evt
            this.approvalTab = evt + 1
            this.$refs.TableMess.refresh({ approvalTab: evt + 1 })
        },
        search() {
            this.$refs.TableMess.refresh({ name: this.keyword, approvalTab: this.approvalTab })
        },
        toApproval() {
            this.$router.push({
                name: 'mobileDocumentApproval',
                params: {
                    index: 1,
                },
            })
        },
        toBillRegister() {
            this.$router.push({ name: 'mobileBillRegister' })
        },
        initNumber() {
            pendingNumber()
                .then((res) => {
                    if (res.code === 200) {
                        this.pendingNumber = res.data
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        Filter(arr, s) {
            // eslint-disable-next-line eqeqeq
            const values = arr.filter((item) => item.code == s)
            if (values.length > 0) {
                return values[0].value
            }
        },
        foramtAcceptStatus(text) {
            switch (Number(text)) {
                case 0:
                    return '待接收'
                case 1:
                    return '已接收'
                default:
                    return ''
            }
        },
    },
}
